.modal {
    .LoadingDots {
        margin: 10px auto;
    }
}
.CancelBooking_secondary_success {
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #58a946;
}

.CancelBooking__nonRefundableOptions {
    p,
    li {
        font-size: 14px;
    }
    p {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    li {
        margin-bottom: 10px;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
}

.bookings-tabs.nav {
    margin-bottom: 20px;

    .nav-link {
        border: none;
        font-weight: 600;
        color: #bcbcbc;
        font-size: 14px;
        // padding: 15px 0;
        padding: 0px 0 15px;
        margin-right: 30px;

        &.active {
            background-color: inherit;
            color: #343434;
        }
    }
}

.booking-box {
    cursor: pointer;
    margin-bottom: 20px; // padding: 16px 22px;
    transition: all 0.2s;
    background-color: white;
    -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    border-radius: 8px !important;
    padding: 30px;

    &:hover {
        -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
    }

    .booking-box__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .badge {
            margin-left: 10px;
        }
    }

    .booking-box__days-left {
        flex-grow: 1;
        text-align: right;
        font-weight: bold;
        color: $primary;
    }

    h4.booking-box__locations {
        font-weight: bold;
        margin-bottom: 0px;

        svg {
            font-size: 16px;
            // color: $primary;
            margin: -2px 4px 0 7px;
        }

        .badge {
            float: none;
            font-size: 55%;
            vertical-align: middle;
            margin-left: 10px;
        }
    }

    p {
        color: #6f6f6f;
        font-weight: normal;
        margin-bottom: 10px;
    }

    a {
        color: $primary !important;
        font-weight: 600;

        svg {
            margin-left: 5px;
        }
    }

    .booking-box__details {
        > span {
            margin-right: 20px;
        }
    }

    .booking-box__days-left {
        font-size: 18px;
    }
}

.bookings-page__cta {
    margin-top: 100px !important;
}

.bookings-page {
    padding-top: 40px !important;
}

.booking-detail-page {
    padding-top: 0px !important;

    h2 > .badge {
        font-size: 55%;
        vertical-align: middle;
        margin-left: 10px;
    }

    .loading-shimmer {
        height: 100px;
        background: linear-gradient(to right, #f2f2f2 8%, #eaeaea 18%, #f2f2f2 33%);
    }

    .breadcrumb {
        padding: 20px 20px !important;
        margin-left: -20px;
        margin-right: -20px;
        background-color: rgba(239, 250, 255, 0.95) !important;
        backdrop-filter: blur(6px);
        margin-bottom: 5px;
        transition: all 0.3s;
        z-index: 10;
        position: relative;

        .loading-shimmer {
            height: 15px;
            padding-top: 0px;
            width: 100px;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0px;
            background: linear-gradient(to right, #f2f2f2 8%, #eaeaea 18%, #f2f2f2 33%);
        }
    }

    .booking-detail-sub-nav-card {
        margin-top: 46px;
        top: 20px;
    }

    .sticky-outer-wrapper.active {
        .booking-detail-sub-nav-card {
            margin-top: 0px;
        }
        .breadcrumb {
            box-shadow: 0px 8px 10px -12px rgba(0, 0, 0, 0.6);
            // border-bottom:1px solid black;
        }
    }

    .booking-detail-sub-nav {
        margin: 10px 0;

        a.nav-link {
            color: #1b1b1b;
            color: $primary;
            padding: 10px 25px;

            &:hover {
                background-color: whitesmoke;
            }

            &.active {
                background-color: #daf2f6;
                box-shadow: inset 6px 0px 0px 2-3px #19b4d1;
            }
        }
    }

    #more-help {
        // background-image: url('/assets/images/need-more-help.png');
        background-size: 320px;
        background-repeat: no-repeat;
        background-position: 102% 105%;
        min-height: 200px;
    }
}

// #payments {
//     padding-bottom: 0px;
//     overflow: hidden;
// }

.payments__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.payments__summary {
    align-items: flex-start;
    padding-left: 20px;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.payments__amounts {
    text-align: right;
    font-weight: 700;
    font-size: 18px;
}

.payments__total-cost {
    color: #707070d9;
    font-weight: bold;
    font-size: 22px;
}

.payments__summary-section--amount-remaining {
    color: $primary;
}

.payments__amount-remaining {
    font-weight: bold;
    font-size: 22px;
}

.payments__divider {
    margin: 20px -30px 0px;
}

.payments__summary-section {
    text-align: right;
    margin-left: 40px;
}

.payments__summary-heading {
    text-transform: uppercase;
    font-size: 11px;
    color: grey;
    font-weight: 600;
}

.payments__card-details {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 12px;
    margin-top: 6px;

    > span {
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
}

.payments__log {
    // background: #F6F7F8;
    margin-left: -30px;
    margin-right: -30px;
    // padding: 30px;
    border-top: 1px solid #ebebeb;
    // max-height: 300px;
    // overflow: auto;
}

.instalment-wrapper {
    // margin-bottom: 20px;

    &.is-disabled {
        opacity: 0.4;
    }

    // &:not(.is-upcoming) {
    //     transform: scale(0.9;)
    // }

    // &:not(.is-disabled):hover {
    //     -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
    //     box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
    // }
}

.instalment {
    color: #000000d9;
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
    // align-items: center;
    // cursor: pointer;
    border-bottom: 1px solid #ebebeb;
}

.instalment__details {
    background: #90a0a4;
    padding: 15px 30px;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.instalment__description {
    width: 100px;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.instalment__date {
    color: #a4a4a4;
    font-size: 14px;
    font-weight: normal;
    margin-left: 20px;
}

.instalment__amount-status-wrapper {
    display: flex;
}

.instalment__amount {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    // border-right: 1px solid #ececec;
    margin-right: 0px;
    // padding-right: 10px;
}

.instalment__caret {
    color: $primary;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
}

.instalment__status {
    // border-right: 1px solid #ececec;
    margin-right: 0px;
    padding-right: 5px;
    display: flex;
    align-items: center;

    button {
        // margin-left: 10px;
        // padding: 10px 24px;
        margin-left: 10px;
        padding: 3px 14px;
        font-size: 14px;
        font-weight: 600;
    }
}

.tab-content p {
    font-size: 14px;
    font-weight: normal;
}

.cancellation-policy__timeline {
    display: flex;
    margin: 20px 0;
    position: relative;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .cancellation-policy__timeline-circles {
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        padding-bottom: 6px;

        .cancellation-policy__timeline-track {
            width: 6px;
            background: linear-gradient(180deg, #b9e8fc 0%, #9adced 44.79%, #19b4d1 100%);
            height: calc(100% - 30px);
            position: absolute;
            margin-top: 9px;
            left: 50%;
            margin-left: -3px;
            top: 0;
            z-index: 0;
        }

        .cancellation-policy__timeline-circle {
            z-index: 1;
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: $primary;

            &:first-child {
                background-color: #58a946;
            }
        }
    }

    .cancellation-policy__timeline-dates {
        padding-bottom: 6px;
        color: #858585;
    }

    .cancellation-policy__timeline-description {
        height: 280px;

        > div {
            // padding:0 0 20px;

            p {
                margin-bottom: 0;
            }
        }

        h4 {
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.booking-detail-section {
    padding: 30px;
    margin-bottom: 30px;

    svg.fa-question-circle {
        color: $primary;
        margin-left: 5px;
    }

    h2 {
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 28px;
    }

    p {
        font-size: 14px;
        font-weight: normal;
    }

    .passenger-information {
        margin-bottom: 20px;

        .passenger-name {
            font-size: 18px;
            font-weight: 600;
            color: #000;
            text-transform: uppercase;

            span {
                text-transform: none;
                color: #8792a6;
                font-size: 16px;
                margin-left: 10px;
                font-weight: 400;
            }
        }
    }

    .table {
        tr td:last-of-type {
            font-weight: bold;
        }

        tr td:first-of-type {
            width: 250px;
        }

        td {
            padding-left: 0;
            padding-right: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
        }

        tr:last-of-type td {
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        }
    }
}

.bookings-page {
    .loading-shimmer {
        opacity: 0.6;
        height: 100px;
    }
}

.Modal__cancelBooking {
    .modal-body {
        padding-top: 20px;
    }

    .CancelBooking__confirmButton {
        width: 100%;
        margin-top: 10px;
    }

    .CancelBooking__confirmButtonTerms {
        font-size: 14px;
        font-weight: 600;
        margin-top: 20px;
    }
}

@include media-breakpoint-up(sm) {
    .instalment__status {
        order: -1;
        padding-right: 15px;
    }
}

@include media-breakpoint-down(sm) {
    .instalment {
        padding: 15px 10px;
    }

    .booking-detail-section {
        padding: 25px;

        h2 {
            font-size: 20px;
        }
    }

    .payments__log {
        padding: 20px;
    }

    .search-result-box.no-select {
        margin: 0px -15px;
    }

    .payments__header {
        display: block;
    }

    .payments__summary {
        padding-left: 0px;
        display: block;
    }

    .payments__card-details {
        margin-bottom: 20px;
    }

    .payments__summary-section {
        margin-left: 0px;
        margin-right: 40px;
        display: inline-block;
        text-align: left;

        &:last-child {
            margin-right: 0px;
        }
    }

    .instalment__amount-status-wrapper {
        display: flex;
        flex-direction: row-reverse;
        text-align: right;
    }

    .instalment__description {
        display: block;

        > span {
            display: block;
        }
    }

    .instalment__date {
        margin-left: 0px;
    }

    .instalment__amount,
    .instalment__status {
        justify-content: flex-end;
        padding-right: 5px;
    }

    .instalment__status {
        flex-direction: column;
        flex-flow: column-reverse;
        margin-right: 15px;
        .badge {
            background-color: transparent !important;
            padding: 0 !important;
            margin-top: 5px !important;
        }
    }

    #more-help {
        min-height: 310px !important;
        background-position: 120% 105% !important;
    }

    .booking-box__locations {
        > span {
            // display: block;
            padding-left: 0px;
            margin-left: 0px !important;
            text-align: left;
        }

        .booking-box__days-left {
            float: none !important;
        }
    }

    .booking-box {
        .booking-box__header {
            flex-direction: column;
            align-items: flex-start;

            .badge {
                margin: 10px 0;
            }
        }
    }

    .booking-box__details {
        display: flex;
        flex-direction: column;

        > span {
            &:last-child {
                margin-top: 20px;
            }
        }
    }
}
